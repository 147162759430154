import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/icons/expand.svg'


const _hoisted_1 = { class: "sidebar" }
const _hoisted_2 = { class: "sidebar-inner" }
const _hoisted_3 = { class: "personal-info" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomCard = _resolveComponent("CustomCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_CustomCard, {
        optClass: "step",
        hidden: _ctx.hidden,
        id: "sidebar-card"
      }, {
        "step-number": _withCtx(() => [
          _createElementVNode("div", null, _toDisplayString(_ctx.stepNumber), 1)
        ]),
        "step-title": _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t("NEW_TICKET.ADD_CONTACT_INFO")), 1)
        ]),
        optional: _withCtx(() => [
          _createElementVNode("img", {
            src: _imports_0,
            class: _normalizeClass(["expand", { hidden: !_ctx.hidden }])
          }, null, 2)
        ]),
        content: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _renderSlot(_ctx.$slots, "personal-info")
          ])
        ]),
        _: 3
      }, 8, ["hidden"])
    ])
  ]))
}