
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    insideText: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    tooltip: {
      type: Boolean,
      default: false
    },
    invalid: {
      type: [Boolean, Number],
      default: false
    },
    id: {
      type: String
    }
  },

  setup() {
    return {};
  }
});
