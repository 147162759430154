
import { computed, defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";
import ItemDetail from "@/views/tickets/ItemDetail.vue";
import CustomCard from "@/components/CustomCard.vue";
import CustomSelectItemReason from "@/components/CustomSelectItemReason.vue";
import CustomTextArea from "@/components/CustomTextArea.vue";
import CloseBtn from "@/components/CloseBtn.vue";
import {
  ExtendedOrderItem,
  RmaCreateForm,
  HTMLInputEvent,
  RmaCaseItemReason
} from "@/types";

interface HandleSelection {
  setProperty: Function;
  changeOption: Function;
}

interface UploadImages {
  clickOnUpload: Function;
  loadImage: Function;
  deleteLoadedImage: Function;
  getPicturesLength: Function;
}

function handleSelection(): HandleSelection {
  const setProperty = (item: any, property: string): void => {
    if (item[property]) {
      item[property] = false;
    } else {
      item[property] = true;
    }
  };

  const changeOption = (item: any, option: RmaCaseItemReason): void => {
    item.reason = option;
  };

  return {
    setProperty,
    changeOption
  };
}

function uploadImages(items: Array<ExtendedOrderItem>): UploadImages {
  const clickOnUpload = (event: HTMLInputEvent): void => {
    const inputElement = event.target.nextElementSibling as HTMLElement;
    if (inputElement) {
      inputElement.click();
    }
  };

  const getPicturesLength = (index: number): number | undefined => {
    if (items[index].pictures) {
      return items[index].pictures!.length;
    }
  };

  const loadImage = async (itemIndex: number, event: HTMLInputEvent) => {
    const getItemsWithPicturesArr = (objectName: any): ExtendedOrderItem => {
      return {
        ...objectName,
        pictures: []
      };
    };

    const addEmptyPicture = async (): Promise<any> => {
      return new Promise(resolve => {
        if (!items[itemIndex].pictures) {
          items[itemIndex] = getItemsWithPicturesArr(items[itemIndex]);
        }
        items[itemIndex].pictures!.push({
          url: ""
        });
        resolve(items[itemIndex]);
      });
    };

    const addSrcToImage = (
      outputImage: HTMLImageElement,
      files: FileList | null,
      indexToUploadTo: number
    ): void => {
      const updatePictures = (
        picArray: any,
        picIndex: number,
        newSrc: string,
        file: File
      ): void => {
        picArray[picIndex].url = newSrc;
        picArray[picIndex].file = file;
      };

      if (outputImage && files) {
        const newSrc = URL.createObjectURL(files[0]);
        outputImage.src = newSrc;
        updatePictures(
          items[itemIndex].pictures,
          indexToUploadTo,
          newSrc,
          files[0]
        );
        outputImage.onload = function() {
          URL.revokeObjectURL(outputImage.src);
        };
      }
    };

    items[itemIndex] = await addEmptyPicture();

    const indexToUploadTo = getPicturesLength(itemIndex)
      ? getPicturesLength(itemIndex)! - 1
      : 0;
    const outputImage = document.getElementById(
      "picture-" + itemIndex + "-" + indexToUploadTo
    )?.firstElementChild as HTMLImageElement;
    addSrcToImage(outputImage, event.target.files, indexToUploadTo);
  };

  const deleteLoadedImage = (itemIndex: number, picIndex: number): void => {
    items[itemIndex].pictures?.splice(picIndex, 1);
  };

  return {
    clickOnUpload,
    loadImage,
    deleteLoadedImage,
    getPicturesLength
  };
}
export default defineComponent({
  components: {
    ItemDetail,
    CustomCard,
    CloseBtn,
    CustomTextArea,
    CustomSelectItemReason
  },
  props: {
    data: {
      type: Object as PropType<RmaCreateForm>,
      required: true
    },
    items: {
      type: Object as PropType<Array<ExtendedOrderItem>>,
      required: true
    },
    chosenItems: {
      type: Object as PropType<Array<ExtendedOrderItem>>,
      required: true
    },
    hidden: {
      type: Boolean
    },
    isCreatedRmaValid: {
      type: Boolean,
      required: true
    },
    doneButtonClicked: {
      type: Boolean,
      required: true
    }
  },
  setup(props) {
    const { t } = useI18n();

    const claimReasons = computed(() => props.data.claimReasons);
    const {
      clickOnUpload,
      loadImage,
      deleteLoadedImage,
      getPicturesLength
    } = uploadImages(props.items);

    const { setProperty, changeOption } = handleSelection();

    const isSelectInvalid = (item: ExtendedOrderItem) =>
      props.chosenItems.length !== 0 &&
      !props.isCreatedRmaValid &&
      props.doneButtonClicked &&
      !item.reason &&
      item.isChosen;

    return {
      t,
      claimReasons,
      clickOnUpload,
      loadImage,
      deleteLoadedImage,
      getPicturesLength,
      setProperty,
      isSelectInvalid,
      changeOption
    };
  }
});
