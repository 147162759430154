
import {
  onBeforeUnmount,
  computed,
  ComputedRef,
  defineComponent,
  inject,
  onMounted,
  Ref,
  ref,
  watch
} from "vue";
import { useQuery, useResult, useMutation } from "@vue/apollo-composable";
import prepareRmaCreateForm from "@/graphql/prepareRmaCreateForm.query.gql";
import rmaCreateMutation from "@/graphql/rmaCreate.mutation.gql";
import {
  RmaCreateForm,
  ExtendedOrderItem,
  RmaCaseInput,
  RmaCaseItemInput
} from "@/types";
import { defaultRmaForm } from "@/constants";
import { useRoute, useRouter, onBeforeRouteLeave } from "vue-router";
import { useI18n } from "vue-i18n";
import { email, required, minLength, alphaNum } from "@vuelidate/validators";
import { phone } from "@/validators";
import { useVuelidate } from "@vuelidate/core";
import { invalidTexts, sidebarAnimation, focusOnIvalids } from "@/utils";
import Headline from "@/views/tickets/Headline.vue";
import TypeChoice from "@/views/tickets/new_ticket/TypeChoice.vue";
import ItemsChoice from "@/views/tickets/new_ticket/ItemsChoice.vue";
import NewOrder from "@/views/tickets/new_ticket/NewOrder.vue";
import Payment from "@/views/tickets/new_ticket/Payment.vue";
import Sidebar from "@/views/tickets/new_ticket/Sidebar.vue";
import Button from "@/components/Button.vue";
import NotFound from "@/components/NotFound.vue";
import CustomInput from "@/components/CustomInput.vue";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";

interface QueryOptions {
  context: {
    headers: {
      "X-Auth-Token": string | null;
    };
  };
}
interface QueryVariables {
  rmaCase: RmaCaseInput;
  lang: string;
}

interface HandleRmaCreateFormQuery {
  rmaCreateFormResult: Readonly<Ref<Readonly<RmaCreateForm>>>;
  loading: Ref<boolean>;
  rmaItemsCopy: Ref<Array<ExtendedOrderItem>>;
  createDefaultRmaItemsCopy: Function;
  formCredentials: Ref<FormCredentials>;
  prefillFormCredentials: Function;
  prepareFormError: Ref<null|Error>;
}

interface HandleRmaCreating {
  createNewTicket: Function;
  doneButtonClicked: Ref<boolean>;
  createRmaLoading: Ref<boolean>;
  rmaHasBeenCreated: Ref<boolean>;
}

interface GetAllDefaultRmaInfo {
  ticketType: Ref<string>;
  orderId: string;
  paymentType: Ref<"IBAN" | "BANK_ACCOUNT" | "">;
  queryOptions: Ref<QueryOptions>;
}

interface ValidityCheck {
  allChosenItemsValid: ComputedRef<boolean>;
  isCreatedRmaValid: ComputedRef<boolean>;
  isPaymentNumberValid: Ref<boolean>;
  v$: any;
}

interface FormCredentials {
  newOrderNo: string;
  bankAccount: string;
  iban: string;
  name: string;
  email: string;
  phone: string;
}

function handleRmaCreateFormQuery(
  orderId: string,
  lang: Ref<string>,
  queryOptions: Ref<QueryOptions>,
  paymentType: Ref<"IBAN" | "BANK_ACCOUNT" | "">
): HandleRmaCreateFormQuery {
  const formVariables = ref({
    orderId: orderId,
    lang: lang
  });

  const { result, loading, onResult, error: prepareFormError } = useQuery(
    prepareRmaCreateForm,
    formVariables,
    {
      ...queryOptions.value,
      fetchPolicy: "network-only"
    }
  );
  const rmaCreateFormResult = useResult(
    result,
    defaultRmaForm,
    data => data.prepareRmaCreateForm
  );

  const rmaItemsCopy = ref([]) as Ref<Array<ExtendedOrderItem>>;
  const createDefaultRmaItemsCopy = (): Array<ExtendedOrderItem> => {
    return JSON.parse(JSON.stringify(rmaCreateFormResult.value.items));
  };

  const formCredentials = ref({
    newOrderNo: "",
    iban: "",
    bankAccount: "",
    name: "",
    email: "",
    phone: ""
  }) as Ref<FormCredentials>;

  const prefillFormCredentials = () => {
    if (rmaCreateFormResult.value.order.customer) {
      formCredentials.value = {
        ...formCredentials.value,
        name: rmaCreateFormResult.value.order.customer.name,
        email: rmaCreateFormResult.value.order.customer.email,
        phone: rmaCreateFormResult.value.order.customer.phone
      };
    }
  };

  onResult(() => {
    rmaItemsCopy.value = createDefaultRmaItemsCopy();
    prefillFormCredentials();
    rmaCreateFormResult.value.bankAccount
      ? (paymentType.value = "BANK_ACCOUNT")
      : (paymentType.value = "IBAN");
  });

  return {
    rmaCreateFormResult,
    loading,
    rmaItemsCopy,
    createDefaultRmaItemsCopy,
    formCredentials,
    prefillFormCredentials,
    prepareFormError
  };
}

function handleRmaCreating(
  getRmaCreateQueryVariables: Function,
  queryOptions: Ref<QueryOptions>,
  isCreatedRmaValid: ComputedRef<boolean>,
  cookies: any,
  emitter: any,
  v$: any,
  router: any
): HandleRmaCreating {
  const doneButtonClicked = ref(false) as Ref<boolean>;
  const rmaHasBeenCreated = ref(false) as Ref<boolean>;
  const {
    mutate: createRma,
    loading: createRmaLoading,
    onDone,
    onError
  } = useMutation(rmaCreateMutation, queryOptions);

  const disableDoneButton = (): void => {
    rmaHasBeenCreated.value = true;
  };

  const createNewTicket = (): void => {
    doneButtonClicked.value = true;
    v$.value.$touch();

    if (isCreatedRmaValid.value) {
      createRma(getRmaCreateQueryVariables().value);

      onDone(result => {
        if (result.data.rmaCreate.result) {
          disableDoneButton();
          router.push({
            name: "Done",
            params: {
              rmaCode: result.data.rmaCreate.rmaCase.code,
              claimLetterUrl: result.data.rmaCreate.rmaCase.claimLetterUrl,
              nextSteps: result.data.rmaCreate.texts.nextSteps,
              successMessage: result.data.rmaCreate.texts.successMessage
            }
          });
          cookies.setCookie("donePageAccessible", "true");
        } else {
          result.data.rmaCreate.reason.id === 8 ||
          result.data.rmaCreate.reason.id === 7
            ? emitter.emit("showAlert", { type: "wrongInput" })
            : emitter.emit("showAlert", { type: "warning" });
        }
      });
      onError(error => {
        if (!error.message.includes("401")) {
          emitter.emit("showAlert", { type: "error" });
        }
      });
    } else focusOnIvalids();
  };

  return {
    createNewTicket,
    doneButtonClicked,
    createRmaLoading,
    rmaHasBeenCreated
  };
}

function getAllDefaultRmaInfo(cookies: any): GetAllDefaultRmaInfo {
  return {
    ticketType: ref("") as Ref<string>,
    orderId: useRoute().params.oid as string,
    paymentType: ref("IBAN") as Ref<"IBAN" | "BANK_ACCOUNT" | "">,
    queryOptions: ref({
      context: {
        headers: {
          "X-Auth-Token": cookies.getCookie("token")
        }
      }
    })
  };
}

function validityCheck(
  chosenItems: Ref<Array<ExtendedOrderItem>>,
  ticketType: Ref<string>,
  orderId: string,
  paymentType: Ref<"IBAN" | "BANK_ACCOUNT" | "">,
  formCredentials: Ref<FormCredentials>
): ValidityCheck {
  const rules = {
    newOrderNo: { required, alphaNum },
    iban: { required },
    bankAccount: { required },
    name: {
      required,
      minLength: minLength(5)
    },
    email: { required, email },
    phone: { required, phone, minLength: minLength(8) }
  };

  const v$ = useVuelidate(rules, formCredentials);

  const isPaymentNumberValid = ref(false) as Ref<boolean>;

  const allChosenItemsValid = computed(() => {
    return chosenItems.value.every((item: ExtendedOrderItem) => item.reason);
  }) as ComputedRef<boolean>;

  const isFormCredentialsValid = computed(() => {
    const currentPaymentTypeInvalidValue = (paymentType: string): boolean =>
      paymentType === "BANK_ACCOUNT"
        ? v$.value.bankAccount.$invalid
        : v$.value.iban.$invalid;

    return ticketType.value !== "CHANGE"
      ? !currentPaymentTypeInvalidValue(paymentType.value) &&
          !v$.value.name.$invalid &&
          !v$.value.email.$invalid &&
          !v$.value.phone.$invalid
      : !currentPaymentTypeInvalidValue(paymentType.value) &&
          !v$.value.newOrderNo.$invalid &&
          !v$.value.name.$invalid &&
          !v$.value.email.$invalid &&
          !v$.value.phone.$invalid;
  });

  const isCreatedRmaValid = computed(() =>
    (ticketType.value === "RETURN" ||
      ticketType.value === "CHANGE" ||
      ticketType.value === "CLAIM") &&
    orderId !== "" &&
    isPaymentNumberValid.value &&
    isFormCredentialsValid.value &&
    chosenItems.value.length !== 0 &&
    allChosenItemsValid.value
      ? true
      : false
  ) as ComputedRef<boolean>;

  watch(paymentType, () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (clearPaymentCredentials => {
      formCredentials.value.iban = "";
      formCredentials.value.bankAccount = "";
      v$.value.iban.$reset();
      v$.value.bankAccount.$reset();
    })();
  });
  return {
    allChosenItemsValid,
    isCreatedRmaValid,
    isPaymentNumberValid,
    v$
  };
}

function beforeLeaveGuard(
  emitter: any,
  router: any,
  ticketType: Ref<string>
): void {
  const alreadyClicked = ref(false) as Ref<boolean>;
  onBeforeRouteLeave((to, _, next) => {
    if (ticketType.value !== "" && to.path == "/") {
      if (alreadyClicked.value) {
        next();
      } else {
        emitter.emit("showAlert", { type: "leave" });
        next(false);
        emitter.on("closeNRoute", () => {
          router.push("/");
          alreadyClicked.value = true;
        });
      }
    } else next();
  });
}

export default defineComponent({
  components: {
    Headline,
    TypeChoice,
    ItemsChoice,
    NewOrder,
    Payment,
    Sidebar,
    Button,
    ClipLoader,
    NotFound,
    CustomInput
  },

  setup() {
    const { t, locale: lang } = useI18n();
    const router = useRouter();
    const cookies = inject("cookies") as any;
    const emitter = inject("emitter") as any;

    const {
      ticketType,
      orderId,
      paymentType,
      queryOptions
    } = getAllDefaultRmaInfo(cookies);
    const { getInvalidText } = invalidTexts(t);

    const {
      rmaCreateFormResult,
      loading,
      rmaItemsCopy,
      createDefaultRmaItemsCopy,
      formCredentials,
      prefillFormCredentials,
      prepareFormError
    } = handleRmaCreateFormQuery(orderId, lang, queryOptions, paymentType);

    const chosenItems = computed(() => {
      return rmaItemsCopy.value.filter(
        (item: ExtendedOrderItem) => item.isChosen
      );
    }) as ComputedRef<Array<ExtendedOrderItem>>;

    const {
      allChosenItemsValid,
      isCreatedRmaValid,
      isPaymentNumberValid,
      v$
    } = validityCheck(
      chosenItems,
      ticketType,
      orderId,
      paymentType,
      formCredentials
    );

    const getRmaCreateQueryVariables = (): Ref<QueryVariables> => {
      return ref({
        rmaCase: {
          type: ticketType.value,
          orderId: orderId,
          newOrder:
            ticketType.value === "CHANGE"
              ? {
                  orderId: formCredentials.value.newOrderNo,
                  manual: true
                }
              : null,
          customer: {
            name: formCredentials.value.name,
            email: formCredentials.value.email,
            phone: formCredentials.value.phone
          },
          iban:
            formCredentials.value.iban === ""
              ? null
              : formCredentials.value.iban,
          bankAccount:
            formCredentials.value.bankAccount === ""
              ? null
              : formCredentials.value.bankAccount,
          items: chosenItems.value.map(
            (item: ExtendedOrderItem): RmaCaseItemInput => {
              const newItem = {
                orderItemId: item.id,
                pieces: item.pieces,
                reason: {
                  id: item.reason!.id
                },
                description: item.note ? item.note : ""
              };

              if (item.pictures) {
                return {
                  ...newItem,
                  attachments: item.pictures!.map(
                    (picture: any) => picture.file
                  )
                };
              }

              return newItem;
            }
          )
        },
        lang: lang
      });
    };

    const {
      createNewTicket,
      doneButtonClicked,
      createRmaLoading,
      rmaHasBeenCreated
    } = handleRmaCreating(
      getRmaCreateQueryVariables,
      queryOptions,
      isCreatedRmaValid,
      cookies,
      emitter,
      v$,
      router
    );

    beforeLeaveGuard(emitter, router, ticketType);

    const addAnimation = () => {
      if (ticketType.value) sidebarAnimation();
    };

    onMounted(() => {
      rmaItemsCopy.value = createDefaultRmaItemsCopy();
      prefillFormCredentials();
      window.addEventListener("scroll", addAnimation);
    });

    onBeforeUnmount(() => {
      window.removeEventListener("scroll", addAnimation);
    });

    return {
      ticketType,
      claimReasons: computed(() => rmaCreateFormResult.value.claimReasons),
      orderId,
      rmaCreateFormResult,
      loading,
      rmaItemsCopy,
      createNewTicket,
      isCreatedRmaValid,
      chosenItems,
      doneButtonClicked,
      allChosenItemsValid,
      createRmaLoading,
      t,
      isPaymentNumberValid,
      v$,
      formCredentials,
      getInvalidText,
      prepareFormError,
      rmaHasBeenCreated,
      paymentType
    };
  }
});
