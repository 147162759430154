
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import CustomCard from "@/components/CustomCard.vue";

export default defineComponent({
  components: {
    CustomCard
  },
  props: {
    hidden: {
      type: Boolean
    },
    stepNumber: {
      type: Number,
      required: true
    }
  },
  setup() {
    const { t } = useI18n();

    return {
      t
    };
  }
});
