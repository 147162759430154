
import { defineComponent, inject, PropType } from "vue";
import { useI18n } from "vue-i18n";
import CustomCard from "@/components/CustomCard.vue";
import CustomInput from "@/components/CustomInput.vue";
import { invalidTexts } from "@/utils";
import { MarketConfig } from "@/types";
import { IBAN } from "ibankit";

export default defineComponent({
  components: {
    CustomCard,
    CustomInput
  },
  props: {
    iban: {
      type: String,
      required: true
    },
    bankAccount: {
      type: String,
      required: true
    },
    paymentType: {
      type: String,
      required: true
    },
    validation: {
      type: Object as PropType<any>,
      required: true
    },
    isPaymentNumberValid: {
      type: Boolean,
      required: true
    },
    hidden: {
      type: Boolean,
      required: true
    },
    doneButtonClicked: {
      type: Boolean,
      required: true
    },
    stepNumber: {
      type: Number,
      required: true
    },
    hasBankAccount: {
      type: Boolean
    }
  },
  emits: ["update:iban", "update:isPaymentNumberValid", "paymentTypeInput"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const { countryCode, change } = inject("marketConfig") as MarketConfig;
    const { getInvalidText } = invalidTexts(t);

    const validatePaymentNumber = (paymentNumber: string) => {
      if (props.paymentType === "IBAN")
        emit("update:isPaymentNumberValid", IBAN.isValid(paymentNumber));
      else {
        const bankAccountRegex =
          countryCode === "HU"
            ? /^(?:([0-9]{0,8})-)?([0-9]{8})-([0-9]{8})$/
            : /^(?:([0-9]{1,6})-)?([0-9]{2,10})\/([0-9]{4})$/;

        const isBankAccountValid = bankAccountRegex.test(paymentNumber);
        emit("update:isPaymentNumberValid", isBankAccountValid);
      }
    };

    return {
      t,
      change,
      getInvalidText,
      validatePaymentNumber
    };
  }
});
