import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-041cd898"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "loader"
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 2 }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = {
  key: 1,
  id: "new-ticket"
}
const _hoisted_7 = { class: "layout-wrapper" }
const _hoisted_8 = { class: "layout" }
const _hoisted_9 = { class: "content-inner" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_clip_loader = _resolveComponent("clip-loader")!
  const _component_NotFound = _resolveComponent("NotFound")!
  const _component_Headline = _resolveComponent("Headline")!
  const _component_TypeChoice = _resolveComponent("TypeChoice")!
  const _component_ItemsChoice = _resolveComponent("ItemsChoice")!
  const _component_NewOrder = _resolveComponent("NewOrder")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Payment = _resolveComponent("Payment")!
  const _component_CustomInput = _resolveComponent("CustomInput")!
  const _component_Sidebar = _resolveComponent("Sidebar")!

  return (_ctx.loading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_clip_loader, {
          color: "#777777",
          size: "100px"
        })
      ]))
    : (_ctx.prepareFormError)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.prepareFormError.message.includes('401'))
            ? (_openBlock(), _createElementBlock("div", _hoisted_3))
            : (_openBlock(), _createBlock(_component_NotFound, {
                key: 1,
                type: "error"
              }))
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (
        !_ctx.rmaCreateFormResult.result ||
          !_ctx.rmaCreateFormResult.items ||
          (_ctx.rmaCreateFormResult.items && _ctx.rmaCreateFormResult.items.length === 0)
      )
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(_component_NotFound, { type: "noresult" }, {
                  default: _withCtx(() => [
                    _createElementVNode("h1", null, _toDisplayString(_ctx.t("ERRORS.ERROR_TITLE")), 1),
                    _createElementVNode("p", null, _toDisplayString(_ctx.t("ERRORS.NEWTICKET_NOT_FOUND")), 1)
                  ]),
                  _: 1
                })
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createVNode(_component_Headline, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t("ORDER.TITLE")) + " " + _toDisplayString(_ctx.rmaCreateFormResult.order.originId), 1)
                  ]),
                  _: 1
                }),
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("div", _hoisted_9, [
                      _createVNode(_component_TypeChoice, {
                        ticketType: _ctx.ticketType,
                        "onUpdate:ticketType": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.ticketType) = $event)),
                        formTypes: _ctx.rmaCreateFormResult.formTypes,
                        orderAfterDeadline: _ctx.rmaCreateFormResult.orderAfterDeadline,
                        "data-cy": "type-choice"
                      }, null, 8, ["ticketType", "formTypes", "orderAfterDeadline"]),
                      _createVNode(_component_ItemsChoice, {
                        data: _ctx.rmaCreateFormResult,
                        items: _ctx.rmaItemsCopy,
                        chosenItems: _ctx.chosenItems,
                        isCreatedRmaValid: _ctx.isCreatedRmaValid,
                        doneButtonClicked: _ctx.doneButtonClicked,
                        hidden: _ctx.ticketType === '',
                        "data-cy": "items-choice"
                      }, null, 8, ["data", "items", "chosenItems", "isCreatedRmaValid", "doneButtonClicked", "hidden"]),
                      (_ctx.ticketType === 'CHANGE')
                        ? (_openBlock(), _createBlock(_component_NewOrder, {
                            key: 0,
                            newOrderNo: _ctx.formCredentials.newOrderNo,
                            "onUpdate:newOrderNo": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formCredentials.newOrderNo) = $event)),
                            validation: _ctx.v$.newOrderNo,
                            doneButtonClicked: _ctx.doneButtonClicked,
                            hidden: _ctx.ticketType === '',
                            "data-cy": "new-order-id"
                          }, null, 8, ["newOrderNo", "validation", "doneButtonClicked", "hidden"]))
                        : _createCommentVNode("", true),
                      _createVNode(_component_Payment, {
                        stepNumber: _ctx.ticketType === 'CHANGE' ? 4 : 3,
                        iban: _ctx.formCredentials.iban,
                        "onUpdate:iban": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formCredentials.iban) = $event)),
                        bankAccount: _ctx.formCredentials.bankAccount,
                        "onUpdate:bankAccount": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formCredentials.bankAccount) = $event)),
                        validation: { iban: _ctx.v$.iban, bankAccount: _ctx.v$.bankAccount },
                        doneButtonClicked: _ctx.doneButtonClicked,
                        isPaymentNumberValid: _ctx.isPaymentNumberValid,
                        "onUpdate:isPaymentNumberValid": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.isPaymentNumberValid) = $event)),
                        hidden: _ctx.ticketType === '',
                        hasBankAccount: !!_ctx.rmaCreateFormResult.bankAccount,
                        paymentType: _ctx.paymentType,
                        "onUpdate:paymentType": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.paymentType) = $event)),
                        onPaymentTypeInput: _cache[6] || (_cache[6] = ($event: any) => (_ctx.paymentType = $event)),
                        "data-cy": "payment"
                      }, {
                        submit: _withCtx(() => [
                          _createVNode(_component_Button, {
                            onClick: _ctx.createNewTicket,
                            text: _ctx.t('NEW_TICKET.DONE'),
                            disabled: !_ctx.isCreatedRmaValid || _ctx.rmaHasBeenCreated,
                            loadingState: _ctx.createRmaLoading,
                            class: "done-btn",
                            "data-cy": "done-btn-payment"
                          }, null, 8, ["onClick", "text", "disabled", "loadingState"])
                        ]),
                        _: 1
                      }, 8, ["stepNumber", "iban", "bankAccount", "validation", "doneButtonClicked", "isPaymentNumberValid", "hidden", "hasBankAccount", "paymentType"])
                    ]),
                    _createVNode(_component_Sidebar, {
                      id: "sidebar",
                      stepNumber: _ctx.ticketType === 'CHANGE' ? 5 : 4,
                      hidden: _ctx.ticketType === '',
                      "data-cy": "contact"
                    }, {
                      "personal-info": _withCtx(() => [
                        _createVNode(_component_CustomInput, {
                          insideText: _ctx.formCredentials.name,
                          "onUpdate:insideText": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.formCredentials.name) = $event)),
                          label: _ctx.t('NEW_TICKET.NAME_PLACEHOLDER'),
                          invalid: _ctx.v$.name.$errors.length,
                          onBlur: _ctx.v$.name.$touch
                        }, {
                          invalidText: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.getInvalidText(_ctx.v$.name.$errors[0].$validator)), 1)
                          ]),
                          _: 1
                        }, 8, ["insideText", "label", "invalid", "onBlur"]),
                        _createVNode(_component_CustomInput, {
                          insideText: _ctx.formCredentials.email,
                          "onUpdate:insideText": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.formCredentials.email) = $event)),
                          label: _ctx.t('COMMON.EMAIL'),
                          invalid: _ctx.v$.email.$errors.length,
                          onBlur: _ctx.v$.email.$touch
                        }, {
                          invalidText: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.getInvalidText(_ctx.v$.email.$errors[0].$validator)), 1)
                          ]),
                          _: 1
                        }, 8, ["insideText", "label", "invalid", "onBlur"]),
                        _createVNode(_component_CustomInput, {
                          insideText: _ctx.formCredentials.phone,
                          "onUpdate:insideText": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.formCredentials.phone) = $event)),
                          label: _ctx.t('COMMON.PHONE'),
                          invalid: _ctx.v$.phone.$errors.length,
                          onBlur: _ctx.v$.phone.$touch
                        }, {
                          invalidText: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.getInvalidText(_ctx.v$.phone.$errors[0].$validator)), 1)
                          ]),
                          _: 1
                        }, 8, ["insideText", "label", "invalid", "onBlur"]),
                        _createVNode(_component_Button, {
                          onClick: _ctx.createNewTicket,
                          text: _ctx.t('NEW_TICKET.DONE'),
                          disabled: !_ctx.isCreatedRmaValid || _ctx.rmaHasBeenCreated,
                          loadingState: _ctx.createRmaLoading,
                          class: "done-btn",
                          "data-cy": "done-btn-contact"
                        }, null, 8, ["onClick", "text", "disabled", "loadingState"])
                      ]),
                      _: 1
                    }, 8, ["stepNumber", "hidden"])
                  ])
                ])
              ]))
        ]))
}