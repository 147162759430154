import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, createVNode as _createVNode, renderSlot as _renderSlot, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/icons/expand.svg'


const _hoisted_1 = { class: "credit-card-info" }
const _hoisted_2 = { class: "info-text" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "payment-inputs" }
const _hoisted_6 = {
  key: 0,
  class: "add-credit-card"
}
const _hoisted_7 = { class: "submit-ticket" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomInput = _resolveComponent("CustomInput")!
  const _component_CustomCard = _resolveComponent("CustomCard")!

  return (_openBlock(), _createBlock(_component_CustomCard, {
    optClass: "step",
    hidden: _ctx.hidden
  }, {
    "step-number": _withCtx(() => [
      _createElementVNode("div", null, _toDisplayString(_ctx.stepNumber), 1)
    ]),
    "step-title": _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.t("NEW_TICKET.ADD_IBAN")), 1)
    ]),
    optional: _withCtx(() => [
      _createElementVNode("img", {
        src: _imports_0,
        class: _normalizeClass(["expand", { hidden: !_ctx.hidden }])
      }, null, 2)
    ]),
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.change)
            ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.t("NEW_TICKET.IBAN_TEXT_CHANGE")), 1))
            : (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.t("NEW_TICKET.IBAN_TEXT")), 1))
        ]),
        _createElementVNode("div", _hoisted_5, [
          (_ctx.hasBankAccount)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createVNode(_component_CustomInput, {
                  id: "bank-account-input",
                  label: _ctx.t('NEW_TICKET.BANK_ACCOUNT_PLACEHOLDER'),
                  insideText: _ctx.bankAccount,
                  tooltip: true,
                  invalid: 
                (!_ctx.isPaymentNumberValid && _ctx.validation.bankAccount.$dirty) ||
                  _ctx.validation.bankAccount.$errors.length
              ,
                  onInput: _cache[0] || (_cache[0] = ($event: any) => {
                _ctx.validatePaymentNumber($event.target.value);
                _ctx.$emit('update:bankAccount', $event.target.value);
              }),
                  onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.validation.bankAccount.$touch()))
                }, _createSlots({
                  tooltipText: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t("NEW_TICKET.TOOLTIPS.BANK_ACCOUNT")), 1)
                  ]),
                  _: 2
                }, [
                  (_ctx.validation.bankAccount.$errors.length)
                    ? {
                        name: "invalidText",
                        fn: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.getInvalidText(_ctx.validation.bankAccount.$errors[0].$validator)), 1)
                        ])
                      }
                    : (!_ctx.isPaymentNumberValid)
                      ? {
                          name: "invalidText",
                          fn: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.t("NEW_TICKET.INVALIDS.INVALID_IBAN")), 1)
                          ])
                        }
                      : undefined
                ]), 1032, ["label", "insideText", "invalid"])
              ]))
            : (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: _normalizeClass(["add-credit-card", { disabled: _ctx.paymentType === 'BANK_ACCOUNT' }])
              }, [
                _createVNode(_component_CustomInput, {
                  id: "iban-input",
                  insideText: _ctx.iban,
                  label: _ctx.t('NEW_TICKET.IBAN_PLACEHOLDER'),
                  tooltip: true,
                  invalid: 
                (!_ctx.isPaymentNumberValid && _ctx.validation.iban.$dirty) ||
                  _ctx.validation.iban.$errors.length
              ,
                  onInput: _cache[2] || (_cache[2] = ($event: any) => (
                _ctx.validatePaymentNumber($event.target.value),
                  _ctx.$emit('update:iban', $event.target.value)
              )),
                  onBlur: _cache[3] || (_cache[3] = ($event: any) => (_ctx.validation.iban.$touch()))
                }, _createSlots({
                  tooltipText: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t("NEW_TICKET.TOOLTIPS.IBAN")), 1)
                  ]),
                  _: 2
                }, [
                  (_ctx.validation.iban.$errors.length)
                    ? {
                        name: "invalidText",
                        fn: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.getInvalidText(_ctx.validation.iban.$errors[0].$validator)), 1)
                        ])
                      }
                    : (!_ctx.isPaymentNumberValid)
                      ? {
                          name: "invalidText",
                          fn: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.t("NEW_TICKET.INVALIDS.INVALID_IBAN")), 1)
                          ])
                        }
                      : undefined
                ]), 1032, ["insideText", "label", "invalid"])
              ], 2))
        ]),
        _createElementVNode("div", _hoisted_7, [
          _renderSlot(_ctx.$slots, "submit")
        ])
      ])
    ]),
    _: 3
  }, 8, ["hidden"]))
}