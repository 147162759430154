
import { defineComponent, inject, PropType } from "vue";
import { useI18n } from "vue-i18n";
import CustomCard from "@/components/CustomCard.vue";
import CustomInput from "@/components/CustomInput.vue";
import Warning from "@/components/Warning.vue";
import { invalidTexts } from "@/utils";
import { MarketConfig } from "@/types";

export default defineComponent({
  components: {
    CustomCard,
    CustomInput,
    Warning
  },
  props: {
    newOrderNo: {
      type: String,
      required: true
    },
    validation: {
      type: Object as PropType<any>,
      required: true
    },
    hidden: {
      type: Boolean
    },
    doneButtonClicked: {
      type: Boolean,
      required: true
    }
  },
  setup() {
    const { t } = useI18n();
    const { getInvalidText } = invalidTexts(t);
    const { marketUrl } = inject("marketConfig") as MarketConfig;

    return {
      t,
      getInvalidText,
      marketUrl
    };
  }
});
