
import { defineComponent, PropType } from "vue";
import { ExtendedOrderItem, RmaCaseItemReason } from "@/types";
import { useI18n } from "vue-i18n";

export default defineComponent({
  props: {
    item: {
      type: Object as PropType<ExtendedOrderItem>,
      required: true
    },
    invalid: {
      type: Boolean
    },
    claimReasons: {
      type: Object as PropType<Array<RmaCaseItemReason>>
    }
  },
  emits: ["selectVisibleChanged", "optionChanged"],
  setup() {
    const { t } = useI18n();
    return {
      t
    };
  }
});
