import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "custom-select-text"
}
const _hoisted_2 = {
  key: 1,
  class: "custom-select-text"
}
const _hoisted_3 = { class: "options-list" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = {
  key: 0,
  class: "invalid-section-text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('selectVisibleChanged'))),
      class: _normalizeClass(["custom-select", {
      active: _ctx.item.selectVisible,
      invalid: _ctx.invalid
    }])
    }, [
      (_ctx.item.reason)
        ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.t(`COMMON.CLAIM_REASONS.${_ctx.item.reason.id}`)), 1))
        : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.t("NEW_TICKET.CHOOSE_REASON")), 1)),
      _createElementVNode("ul", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.claimReasons, (option) => {
          return (_openBlock(), _createElementBlock("li", {
            key: option.id,
            onClick: ($event: any) => (_ctx.$emit('optionChanged', option)),
            class: _normalizeClass(["option", {
          selected: option === _ctx.item.reason
        }])
          }, _toDisplayString(_ctx.t(`COMMON.CLAIM_REASONS.${option.id}`)), 11, _hoisted_4))
        }), 128))
      ])
    ], 2),
    (_ctx.invalid)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.t("NEW_TICKET.CHOOSE_ONE")), 1))
      : _createCommentVNode("", true)
  ], 64))
}