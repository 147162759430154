
import { defineComponent, PropType, inject } from "vue";
import { useI18n } from "vue-i18n";
import CustomCard from "@/components/CustomCard.vue";
import Warning from "@/components/Warning.vue";
import { MarketConfig } from "@/types";

export default defineComponent({
  components: { CustomCard, Warning },
  props: {
    ticketType: {
      type: String,
      required: true
    },
    formTypes: {
      type: Object as PropType<Array<any>>,
      required: true
    },
    orderAfterDeadline: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      disabled: false
    };
  },
  setup(_, { emit }) {
    const { t } = useI18n();

    const { change } = inject("marketConfig") as MarketConfig;

    const setTicketType = (type: string): void => {
      emit("update:ticketType", type);
    };

    return { t, setTicketType, change };
  },
  methods: {
    isButtonDisabled(formType: any) {
      if (this.change && formType.id === "CHANGE") {
        return (this.disabled = true);
      }
    }
  }
});
